import sky from './images/sky.jpg';
import road from './images/road.jpg';
import { 
    GoMarkGithub,
    GoMail,
} from 'react-icons/go';
import {Footer} from './components/Footer.js'
import { 
    useRef,
    useState
} from 'react'
import { 
    Parallax, 
    ParallaxLayer
} from '@react-spring/parallax';



export const App = () => {
    const ref = useRef();  

    return <div >
        <Parallax 
            pages={4} 
            ref={ref}
            className="bg-gradient-to-b from-stone-900 to-emerald-900">
            <ParallaxLayer
                offset={0}
                speed={1}
                factor={2}
                style={{
                    backgroundImage: `url(${sky})`,
                    backgroundSize: 'cover',
                    opacity: 0.5
                }}
            >              
            </ParallaxLayer>

            <ParallaxLayer 
                offset={2} 
                speed={0.5}
                factor={2}
                style={{
                    backgroundImage: `url(${road})`,
                    backgroundSize: 'cover',
                    opacity: 0.5
                }}
            >
                   
            </ParallaxLayer>

            <ParallaxLayer
                offset={0.5}
                speed={0.02}
            >
                <p className="text-white text-5xl px-10 font-serif font-bold">C Berry Web Development</p>
            </ParallaxLayer>

            <ParallaxLayer
                offset={1.5}
                speed={0.02}
            >
                <div className="w-3/4">
                    <p className="text-white text-2xl px-10">
                        I'm Chris and I'm a full-stack web developer<br/><br/>
                        Welcome to my personal website. Don't mind the mess, it's still under construction<br/><br/>
                        I've recently completed my diploma in Website Development through TAFE Qld and am currently searching for my first industry position.
                        During my diploma I created a publishing platform API that managed a Mongo DB datastore with a custom front-end<br/><br/> 
                        It's currently being rebuilt with React Native, check back here soon for a link to the new App!<br/><br/>
                        You can find me on GitHub under cberry191 or you can email me at cberryweb@gmail.com
                    </p>
                </div>
                <div className="flex flex-column justify-evenly gap-5 m-10">
                    <a href="https://github.com/cberry191">
                        <GoMarkGithub size={75} style={{color: 'white'}}/>
                    </a>
                    <a href="mailto:cberryweb@gmail.com">
                        <GoMail size={75} style={{color: 'white'}}/>
                    </a>
                </div>
            </ParallaxLayer>

            <ParallaxLayer
                offset={3.7}
            >
                <Footer />  
            </ParallaxLayer>
        </Parallax>
        
        </div>
}